/* SMALL SCREEN SECTION */
@media only screen and (max-width: 992px) {
  .small-screen-header h2 {
    font-size: 1.6rem !important;
    line-height: 2rem;
  }

  .small-screen-header h4 {
    margin-top: 0 !important;
  }

  .small-screen-data-grid {
    margin-left: -.8rem;
    margin-right: -.8rem;
  }

  .small-screen-breadcrumb {
    margin: 1rem 0 0 0  !important;
  }

  .small-screen-faculty {
    margin: .4rem 0 0 0 !important;
  }

  .small-screen-action-buttons {
    margin: 0 !important;
  }
}
/* END OF SMALL SCREEN SECTION */

a.external[target="_blank"]:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}

.hide-async-elements .async-element {
  display: none !important;
}

.show-async-elements .async-element {
  animation: ease-in fade-in-animation .4s;
  animation-timing-function: ease-in-out;
}

.cursor-disabled {
  cursor: wait !important;
}

.hidden {
  display: none;
}

.center {
  margin: auto;
}

.text-file-compact {
  transform: scale(1, 1.03);
  font-size: .92em;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
}

.content {
  max-width: 1440px;
  margin-bottom: .8rem;
}

.top-bar {
  background-color: black;
}

.top-bar .btn {
  margin: 0.3125rem;
}

.no-margin {
  margin: 0;
}

.info-small {
  font-size: .8rem;
}

.datagrid-wrapper {
  width: 100%;
  max-width: 1440;
  margin: 0 auto;
}

.logo {
  cursor: default;
  font-size: 2em;
  text-transform: uppercase;
  font-family: 'Ultra', serif;
}

.logo-suffix {
  font-size: 0.8em;
  color: red;
}

.logo-long-description {
  cursor: default;
  margin: 0 0.6em;
  line-height: 2em;
}

.loading-spinner {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}

.margin-bottom {
  margin-bottom: 2em;
}

.logo-animated-wrapper {
  margin: .2em;
  height: 6.62em;
  width: 6.62em;
}

.breadcrumb {
  background-color: inherit !important;
  margin: 1rem 0 0 1rem;
  padding: 0;
}

.breadcrumb h2 {
  margin: 0;
  line-height: 1.4em;
}

.faculty {
  margin: 0 0 0 1rem;
}

/* Disable text selection */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.fade-in {
  animation: ease-in fade-in-animation .4s;
  animation-timing-function: ease-in-out;
}

@keyframes fade-in-animation {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.material-icons.md-dark {
  color: #3C4858;
}

.material-icons.md-dark:hover {
  color: #303a46;
}

.material-icons.md-white {
  color: white;
}

.icon-space-right {
  margin-right: .1em;
}

.icon-space-left {
  margin-left: .1em;
}

.file-download-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.file-download {
  /* Bottom margin fixes visual perception of button not being vertically centered (due to box-shadow) */
  margin: 0 0 1px 0;
  box-shadow: none !important;
  padding: 0;
  height: 2.6em;
}

.faq {
  text-align: justify;
  max-width: 882px;
}

.action-buttons {
  margin: 1rem .74rem 2.8em .74rem;
}

.material-icon-prod-style-fix svg {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 2.1875rem;
  color: #1976d2;
}
